<template>
  <div class="page">
    模板页面
  </div>
</template>

<script>
  export default {
    data() {
      return {
      
      }
    },
    mounted() {
     
    },
    methods: {
     
    }
  }
</script>

<style scoped="scoped">
  
</style>
